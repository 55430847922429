import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import { useAuth } from '../../../../app/modules/auth/core/Auth';
import {Buffer} from 'buffer'
import {useLayout} from '../../core'

const itemClass = 'ms-1 ms-lg-3'
//const btnClass = 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const {config} = useLayout()
  const {currentUser, logout} = useAuth()
  const user = Array.isArray(currentUser) && currentUser.length > 0 ? currentUser[0] : currentUser;

  if (typeof user.Foto_profilo === 'string' && user.Foto_profilo.startsWith('data:image')) {
    // Abbiamo già una data URL valida: non fare nulla
  } else if (user.Foto_profilo?.data) {
    // Abbiamo un buffer in user.Foto_profilo.data
    const arrayBuffer = user.Foto_profilo.data
    const base64String = Buffer.from(arrayBuffer).toString('base64')
    user.Foto_profilo = `data:image/png;base64,${base64String}`
  } else {
    // Nessuna immagine, quindi placeholder
    user.Foto_profilo = '/media/avatars/blank_2.png'
  }

  return (
    <div className='app-navbar flex-shrink-0'>

      {/*
      <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div>*/}

      {/*
      <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
        </div>
      </div>*/}

      {/*
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div>*/}

      {/*
      <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div>*/}

      {/*
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>*/}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img alt='Logo' src={user.Foto_profilo} />
        </div>
        <HeaderUserMenu />
      </div>

      {/*{config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}*/}
    </div>
  )
}

export {Navbar}
